import { FeatureToggleConfig } from "./featureToggles";

const getEnv = (name: string, isRequired?: boolean) => {
  const envVar = process.env[name];
  if (!!isRequired && !envVar) {
    throw new Error(`Missing Env Var ${name}`);
  }

  return envVar as string;
};

const config = {
  redirectUri: getEnv("REACT_APP_REDIRECT_URI", true),
  domain: getEnv("REACT_APP_DOMAIN", true),
  clientId: getEnv("REACT_APP_CLIENT_ID", true),
  tenantId: getEnv("REACT_APP_TENANT_ID", true),
  logInUserFlow: getEnv("REACT_APP_LOG_IN_USER_FLOW", true),
  editProfileUserFlow: getEnv("REACT_APP_EDIT_PROFILE_USER_FLOW", true),
  applicationInsightInstrumentationKey: getEnv(
    "REACT_APP_APPLICATION_INSIGHT_INSTRUMENTATION_KEY",
    true
  ),
  /** In order to have access token being returned, one must expose and API and assign API permission to the B2C App */
  scope: getEnv("REACT_APP_SCOPE", true),
  postLogoutRedirectUri: getEnv("REACT_APP_POST_LOGOUT_REDIRECT_URI", true),
  syncfusionLicenseKey: getEnv("REACT_APP_SYNCFUSION_LICENSE", true),
  apiBaseUrl: getEnv("REACT_APP_API_BASE_URL", true),
  theme: getEnv("REACT_APP_THEME", false),
  uploadFileSizeLimit: getEnv("REACT_APP_UPLOAD_FILE_SIZE_LIMIT", true),
  statementsOCRDocPageLimit: getEnv(
    "REACT_APP_STATEMENTS_OCR_DOC_PAGE_LIMIT",
    true
  ),
  // Dev Notes: In the future featureToggleConfig values will comes from the API instead of the Env Settings
  featureToggleConfig: {
    matchups:
      getEnv("REACT_APP_FEATURE_TOGGLE_RELEASE_TOGGLE_MATCHUPS", true) ===
      "true",
    externalSummaryEligibilitySection:
      getEnv(
        "REACT_APP_FEATURE_TOGGLE_RELEASE_TOGGLE_EXTSUMMARYELIGIBILITYSECTION",
        true
      ) === "true",
    signaturePad:
      getEnv("REACT_APP_FEATURE_TOGGLE_RELEASE_TOGGLE_SIGNATURE_PAD", true) ===
      "true",
  } as FeatureToggleConfig,
};

export default config;
