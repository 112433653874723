import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import React from "react";
import ReactDOM from "react-dom/client";
import { initMsalInstance, configurationManager } from "@mdr-ui/core";
import variable from "./config/variables";

configurationManager.init({
  apiBaseUrl: variable.apiBaseUrl,
  statementsOCRDocPageLimit: variable.statementsOCRDocPageLimit,
  featureToggleConfig_externalSummaryEligibilitySection:
    variable.featureToggleConfig.externalSummaryEligibilitySection,
  featureToggleConfig_matchups: variable.featureToggleConfig.matchups,
  featureToggleConfig_signaturePad: variable.featureToggleConfig.signaturePad,
  redirectUri: variable.redirectUri,
  uploadFileSizeLimit: parseInt(variable.uploadFileSizeLimit),
});

initMsalInstance({
  msalConfig: {
    auth: {
      clientId: variable.clientId,
      authority: `https://${variable.domain}/${variable.tenantId}/${variable.logInUserFlow}`,
      redirectUri: variable.redirectUri,
      knownAuthorities: [variable.domain],
      postLogoutRedirectUri: variable.postLogoutRedirectUri,
    },
    cache: {
      cacheLocation: "localStorage",
    },
    system: {
      windowHashTimeout: 60000,
      iframeHashTimeout: 6000,
      loadFrameTimeout: 0,
      asyncPopups: false,
      allowRedirectInIframe: true,
    },
  },
  loginRequestScope: [variable.scope, "openid", "offline_access", "profile"],
  editProfileAuthority: `https://${variable.domain}/${variable.tenantId}/${variable.editProfileUserFlow}`,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
